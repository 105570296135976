import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-filter-data',
  templateUrl: './filter-data.component.html',
  styleUrls: ['./filter-data.component.css']
})
export class FilterDataComponent implements OnInit {

  users: any[];
  selectedUser: string;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {

    this.selectedUser = 'All Athena Reps'

    this.users = [
      {value: 'All Athena Reps'},
      {value: 'Lambert Bunker'},
      {value: 'David Stock'},
      {value: 'Steve Bogosian'},
      {value: 'Tom Howard'},
      {value: 'Andrew Howard'},
      {value: 'No Rep Assigned'}
    ];

  }

  onFilterChange(rep: string) {
    this.clientService.filterData(rep);
  }

}
