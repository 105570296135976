import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/map';

import { AuthService } from '../services/auth.service';
import { listener } from '../../../node_modules/@angular/core/src/render3/instructions';

@Injectable({
  providedIn: 'root'
})
export class AgileService {

  // Data sets
  dataAgileContact = new BehaviorSubject<any[]>(null);
  dataAgileCompany = new BehaviorSubject<any[]>(null);

  constructor(private http: Http, private authService: AuthService) { }

  getAdvisorProfile(AdvisorID: string) {

    this.authService.getAuthenticatedUser().getSession((err, session) => {
      this.http.get('https://sspdlitrdh.execute-api.us-east-1.amazonaws.com/alpha/contacts/advisorid/' + AdvisorID, {
        headers: new Headers({ 'Authorization': session.getIdToken().getJwtToken() })
      })
        .map(
          (response: Response) => response.json()
        )
        .subscribe(
          (data) => {
            this.dataAgileContact.next(data);
          },
          (error) => {
            console.log(error);
          }
        );
    })
  };

  getFirmProfile(FirmID: string) {

    this.authService.getAuthenticatedUser().getSession((err, session) => {
      this.http.get('https://sspdlitrdh.execute-api.us-east-1.amazonaws.com/alpha/companies/firmid/' + FirmID, {
        headers: new Headers({ 'Authorization': session.getIdToken().getJwtToken() })
      })
        .map(
          (response: Response) => response.json()
        )
        .subscribe(
          (data) => {
            this.dataAgileCompany.next(data);
          },
          (error) => {
            console.log(error);
          }
        );
    })
  };

}


