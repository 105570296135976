import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from 'amazon-cognito-identity-js';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

// Define Cognito User Pool
const POOL_DATA = {
  UserPoolId: 'us-east-1_by3oWKHP3',
  ClientId: '6h8th0u2bkuh6g18v7m1a37sh5'
};
const userPool = new CognitoUserPool(POOL_DATA);

@Injectable()
export class AuthService {

  // Application event behvaiors
  authIsLoading = new BehaviorSubject<boolean>(false);
  authDidFail = new BehaviorSubject<boolean>(false);
  authForceChange = new BehaviorSubject<boolean>(false);
  authIsLoggedIn = new BehaviorSubject<boolean>(false);
  authStatusChanged = new Subject<boolean>();

  constructor(private router: Router) {}

  // Login Method
  login(email: string, password: string, callback: any, firstName?: string, lastName?: string, newPassword?: string): void {

    // Set up required data for API call
    const authData = {
      Username: email,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: email,
      Pool: userPool
    };
    const registeredUser = new CognitoUser(userData);
    const that = this;

    // Call Cognito Authenticate User
    registeredUser.authenticateUser(authDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => {

        // This route for Complete Registration
        if (newPassword) {

          // Remove email verified and update required fields
          delete userAttributes.email_verified;
          userAttributes.given_name = firstName;
          userAttributes.family_name = lastName;

          // Call Cognito to update password and provide required fields
          registeredUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });

        // Otherwise, this route to reveal the Complete Registration form
        } else {
          that.authForceChange.next(true);
        };       
      },
      onSuccess: result => this.onLoginSuccess(callback, result),
      onFailure: err => this.onLoginError(callback, err)
    });
  }

  // Successful Login callback method
  private onLoginSuccess = (callback: any, session: CognitoUserSession) => {
    // console.log(session);
    this.authIsLoggedIn.next(true);
    this.router.navigate(['/aum']);
  }

  // Failed Login callback method
  private onLoginError = (callback: any, err) => {
      console.log('AuthService: ' + err.message);
  }

  getAuthenticatedUser() {
    return userPool.getCurrentUser();
  }

  logout() {
    this.getAuthenticatedUser().signOut();
    this.authIsLoggedIn.next(false)
    this.router.navigate(['/login']);
  }

  isAuthenticated(): Observable<boolean> {
    const user = this.getAuthenticatedUser();
    const obs = Observable.create((observer) => {
      if (!user) {
        observer.next(false);
      } else {
        user.getSession((err, session) => {
          if (err) {
            observer.next(false);
          } else {
            if (session.isValid()) {
              observer.next(true);
            } else {
              observer.next(false);
            }
          }
        });
      }
      observer.complete();
    });
    this.authIsLoggedIn.next(obs);
    return obs;
  }

  register(email: string, password: string) { }

}