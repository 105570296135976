import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { AgileService } from '../../services/agile.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AssetAdvisor } from '../../models/asset-detail';
import { ActivityDetail } from '../../models/activity-detail';

@Component({
  selector: 'app-advisor-details',
  templateUrl: './advisor-details.component.html',
  styleUrls: ['./advisor-details.component.css']
})
export class AdvisorDetailsComponent implements OnInit {

  // AdvisorID from URL
  id: string;

  // Assets
  assetAdvisor: AssetAdvisor[];
  advisor: AssetAdvisor;
  advisorProfile: any;
  advisorProperties: any;
  inCRM: boolean;
  searchURL: string;
  contactURL: string;
  crdURL: string;
  secURL: string;
  finraURL: string;
  registrationType: string;

  advisorAddress: {
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    countryname: string;
  }
  addressDisplay: string;
  addressURL: string;

  pure: string;
  dividend: string;
  gt: string;
  gtfsp: string;
  managed: string;
  avf: string;

  // All Activities
  activityAdvisor: any[];
  activity: any[];

  // Sales and Redemptions
  srActivity: any[];
  srTotalActivities: number;
  srTotalValue: number;

  srSales: any[];
  srTotalSales: number;
  srTotalSalesValue: number;

  srRedemptions: any[];
  srTotalRedemptions: number;
  srTotalRedemptionsValue: number;

  // Proposals
  propActivity: any[];
  propTotalActivities: number;
  propTotalValue: number;

  propSales: any[];
  propTotalSales: number;
  propTotalSalesValue: number;

  propRedemptions: any[];
  propTotalRedemptions: number;
  propTotalRedemptionsValue: number;

  selectedIndex: number = 0;

  constructor(
    private clientService: ClientService,
    private agileService: AgileService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService
  ) {
    this.clientService.dataAssetAdvisor.subscribe((data: AssetAdvisor[]) => {
      this.assetAdvisor = data;
    });

    this.clientService.dataActivityDetail.subscribe((data: ActivityDetail[]) => {
      this.activityAdvisor = data;
    });

    this.agileService.dataAgileContact.subscribe((data) => {
      this.advisorProfile = data;
      //console.log(data);
      if (this.advisorProfile) {
        if (this.advisorProfile.length > 0) {
          this.inCRM = true;
          //console.log(this.advisorProfile[0].properties.length);
          const propArray: any[] = this.advisorProfile[0].properties;
          //console.log(propArray);
          this.searchURL = null;
          this.contactURL = 'https://athenainvest.agilecrm.com/#contact/' + this.advisorProfile[0].id

          const arrayToObject = (array, keyField: string, subField?) =>
            array.reduce((obj, item) => {
              if (item[subField]) {
                obj[item[keyField] + '_' + item[subField]] = item.value
              } else {
                obj[item[keyField]] = item.value
              }

              return obj
            }, {})

          propArray.push({type: 'SYSTEM', name: 'id', value: this.advisorProfile[0].id})
          this.advisorProperties = arrayToObject(propArray, 'name', 'subtype')

          //console.log('ATO ', this.advisorProperties);
          if(this.advisorProperties['Individual CRD']) {
            this.crdURL = 'https://www.adviserinfo.sec.gov/Individual/' + this.advisorProperties['Individual CRD']
            this.secURL = 'https://www.adviserinfo.sec.gov/IAPD/Support/ReportStatus.aspx?indvl_pk=' + this.advisorProperties['Individual CRD']
            this.finraURL = 'https://brokercheck.finra.org/individual/summary/' + this.advisorProperties['Individual CRD']
          }

          if(this.advisorProperties.PURE_SMA === 'on' && this.advisorProperties.PURE_UMA === 'on') {
            this.pure = 'SMA / UMA'
          } else if(this.advisorProperties.PURE_SMA === 'on') {
            this.pure = 'SMA'
          } else if(this.advisorProperties.PURE_UMA === 'on') {
            this.pure = 'UMA'
          } else if(this.advisorProperties.PURE === 'on') {
            this.pure = 'Available'
          } else {
            this.pure = 'Not Available'
          }

          if(this.advisorProperties.DIV_SMA === 'on' && this.advisorProperties.DIV_UMA === 'on') {
            this.dividend = 'SMA / UMA'
          } else if(this.advisorProperties.DIV_SMA === 'on') {
            this.dividend = 'SMA'
          } else if(this.advisorProperties.DIV_UMA === 'on') {
            this.dividend = 'UMA'
          } else if(this.advisorProperties.DIV === 'on') {
            this.dividend = 'Available'
          } else {
            this.dividend = 'Not Available'
          }

          if(this.advisorProperties.GT_SMA === 'on' && this.advisorProperties.GT_UMA === 'on') {
            this.gt = 'SMA / UMA'
          } else if(this.advisorProperties.GT_SMA === 'on') {
            this.gt = 'SMA'
          } else if(this.advisorProperties.GT_UMA === 'on') {
            this.gt = 'UMA'
          } else if(this.advisorProperties.GT === 'on') {
            this.gt = 'Available'
          } else {
            this.gt = 'Not Available'
          }

          if(this.advisorProperties.GT_FSPS === 'on' && this.advisorProperties.GT_FSPU === 'on') {
            this.gtfsp = 'SMA / UMA'
          } else if(this.advisorProperties.GT_FSPS === 'on') {
            this.gtfsp = 'SMA'
          } else if(this.advisorProperties.GT_FSPU === 'on') {
            this.gtfsp = 'UMA'
          } else {
            this.gtfsp = 'Not Available'
          }

          if(this.advisorProperties.ME_SMA === 'on' && this.advisorProperties.ME_UMA === 'on') {
            this.managed = 'SMA / UMA'
          } else if(this.advisorProperties.ME_SMA === 'on') {
            this.managed = 'SMA'
          } else if(this.advisorProperties.ME_UMA === 'on') {
            this.managed = 'UMA'
          } else if(this.advisorProperties.ME === 'on') {
            this.managed = 'Available'
          } else {
            this.managed = 'Not Available'
          }

          if(this.advisorProperties.AVF === 'on') {
            this.avf = 'Available'
          } else {
            this.avf = 'Not Available'
          }

          if(this.advisorProperties['Investment Advisor Rep'] === 'on' && this.advisorProperties.Broker === 'on') {
            this.registrationType = 'Dual Registration'
          } else if(this.advisorProperties['Investment Advisor Rep'] === 'on') {
            this.registrationType = 'Investment Advisor Representative'
          } else if(this.advisorProperties.Broker === 'on') {
            this.registrationType = 'Broker'
          } else {
            this.registrationType = 'Unknown'
          }

          if(this.advisorProperties.address_work) {
            this.advisorAddress = JSON.parse(this.advisorProperties.address_work)
            this.addressDisplay = this.advisorAddress.city + ', ' + this.advisorAddress.state
            this.addressURL = `https://www.google.com/maps/place/${this.advisorAddress.address}+${this.advisorAddress.city}+${this.advisorAddress.state}+${this.advisorAddress.zip}`
            this.addressURL = this.addressURL.replace(' ','+')
          } else {
            this.addressDisplay = null;
            this.addressURL = null;
          }

        } else {
          this.advisorProperties = [];
          this.inCRM = false;
          if (this.advisor) {
            this.searchURL = 'https://athenainvest.agilecrm.com/#contacts/search/' + this.advisor.AdvisorName;
          }          
          this.contactURL = null;
          this.crdURL = null;
        }
      };
    });
  }

  arrayToObject(arr: any[], keyField: string) {
    arr.reduce((obj, item) => {
      obj[item.name] = item
      return obj
    }, {})
  };

  ngOnInit() {

    // Get AdvisorID from URL
    this.id = this.route.snapshot.params['id'];
    this.advisor = this.assetAdvisor.filter((e) => e.AdvisorID === this.id)[0];
    this.activity = this.activityAdvisor.filter((e) => e.AdvisorID === this.id)

    // console.log(this.advisor);

    this.agileService.getAdvisorProfile(this.id);

    // All Sales and Redemptions
    this.srActivity = this.activity.filter((e) => {
      return (e.TransactionType == 'Sale' || e.TransactionType == 'Redemption')
    });

    this.srTotalActivities = this.srActivity.length;
    this.srTotalValue = this.srActivity.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Sales
    this.srSales = this.activity.filter((e) => {
      return (e.TransactionType == 'Sale')
    });

    this.srTotalSales = this.srSales.length;
    this.srTotalSalesValue = this.srSales.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Redemptions
    this.srRedemptions = this.activity.filter((e) => {
      return (e.TransactionType == 'Redemption')
    });

    this.srTotalRedemptions = this.srRedemptions.length;
    this.srTotalRedemptionsValue = this.srRedemptions.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // All Proposals
    this.propActivity = this.activity.filter((e) => {
      return (e.TransactionType == 'Proposal' || e.TransactionType == 'Proposed Redemption')
    });

    this.propTotalActivities = this.propActivity.length;
    this.propTotalValue = this.propActivity.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Proposed Sales
    this.propSales = this.activity.filter((e) => {
      return (e.TransactionType == 'Proposal')
    });

    this.propTotalSales = this.propSales.length;
    this.propTotalSalesValue = this.propSales.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Proposed Redemptions
    this.propRedemptions = this.activity.filter((e) => {
      return (e.TransactionType == 'Proposed Redemption')
    });

    this.propTotalRedemptions = this.propRedemptions.length;
    this.propTotalRedemptionsValue = this.propRedemptions.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

  }

  changeTab(tabIndex: number) {
    this.selectedIndex = tabIndex;
  }

}
