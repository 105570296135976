import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { Advisor } from '../../models/asset-detail';

@Component({
  selector: 'app-advisors',
  templateUrl: './advisors.component.html',
  styleUrls: ['./advisors.component.css']
})
export class AdvisorsComponent implements OnInit {

  dataAdvisors: Advisor[];
  advisorCount: number;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientService.dataAdvisors.subscribe((data: Advisor[]) => {
      this.dataAdvisors = data;
    });

    this.clientService.advisorCount.subscribe((data: number) => {
      this.advisorCount = data;
    });

  }

}
