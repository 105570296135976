import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { ActivityDetail, ActivitySummary } from '../../models/activity-detail';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {

  assetDate: string;

  activityDetail: ActivityDetail[];
  activityColumns = ['ActivityDate','AdvisorName','TargetPortfolioName','TransactionType','ActivityValue','AthenaRep'];

  activityProduction: ActivitySummary[];
  activityRedemption: ActivitySummary[];
  activityProposals: ActivitySummary[];
  summaryColumns = ['AdvisorName','FirmName','Activities','ActivityValue','AthenaRep'];

  constructor(private clientService: ClientService) { }

  ngOnInit() {

    this.clientService.dataActivityDetail.subscribe((data: ActivityDetail[]) => {
      this.activityDetail = data;
    });

    this.clientService.assetDate.subscribe((data: string) => {
      this.assetDate = data;
    });

    this.clientService.dataActivityProduction.subscribe((data: ActivitySummary[]) => {
      this.activityProduction = data;
    });

    this.clientService.dataActivityRedemption.subscribe((data: ActivitySummary[]) => {
      this.activityRedemption = data;
    });

    this.clientService.dataActivityProposals.subscribe((data: ActivitySummary[]) => {
      this.activityProposals = data;
    });

  }

  onDownloadClick() {
    this.clientService.downloadActivities()    
  }

}
