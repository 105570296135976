import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { AgileService } from '../../services/agile.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AssetFirm } from '../../models/asset-detail';
import { ActivityDetail } from '../../models/activity-detail';


@Component({
  selector: 'app-firm-details',
  templateUrl: './firm-details.component.html',
  styleUrls: ['./firm-details.component.css']
})
export class FirmDetailsComponent implements OnInit {

  // FirmID from URL
  id: string;

  // CRM Related
  firmProfile: any;
  firmProperties: any;
  inCRM: boolean;
  searchURL: string;
  companyURL: string;
  crdURL: string;
  secURL: string;
  finraURL: string;
  registrationType: string;

  firmAddress: {
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    countryname: string;
  }
  addressDisplay: string;
  addressURL: string;

  // Assets
  assetFirm: any[];
  firm: any;

  // Advisor List
  firmAdvisors: any[];

  // All Activities
  activityFirm: any[];
  activity: any[];

  // Sales and Redemptions
  srActivity: any[];
  srTotalActivities: number;
  srTotalValue: number;

  srSales: any[];
  srTotalSales: number;
  srTotalSalesValue: number;

  srRedemptions: any[];
  srTotalRedemptions: number;
  srTotalRedemptionsValue: number;

  // Proposals
  propActivity: any[];
  propTotalActivities: number;
  propTotalValue: number;

  propSales: any[];
  propTotalSales: number;
  propTotalSalesValue: number;

  propRedemptions: any[];
  propTotalRedemptions: number;
  propTotalRedemptionsValue: number;

  selectedIndex:number=0;

  constructor(
    private clientService: ClientService,
    private agileService: AgileService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService
  ) {
    this.clientService.dataAssetFirm.subscribe((data: AssetFirm[]) => {
      this.assetFirm = data;
    });

    this.clientService.dataActivityDetail.subscribe((data: ActivityDetail[]) => {
      this.activityFirm = data;
    });

    this.agileService.dataAgileCompany.subscribe((data) => {
      this.firmProfile = data;
      // console.log(data);
      if (this.firmProfile) {
        if (this.firmProfile.length > 0) {
          this.inCRM = true;
          //console.log(this.firmProfile[0].properties.length);
          const propArray: any[] = this.firmProfile[0].properties;
          //console.log(propArray);
          this.searchURL = null;
          this.companyURL = 'https://athenainvest.agilecrm.com/#company/' + this.firmProfile[0].id

          const arrayToObject = (array, keyField: string, subField?) =>
            array.reduce((obj, item) => {
              if (item[subField]) {
                obj[item[keyField] + '_' + item[subField]] = item.value
              } else {
                obj[item[keyField]] = item.value
              }

              return obj
            }, {})

          propArray.push({type: 'SYSTEM', name: 'id', value: this.firmProfile[0].id})
          this.firmProperties = arrayToObject(propArray, 'name', 'subtype')

          // console.log('firmProperties ', this.firmProperties);
          if(this.firmProperties['Company CRD']) {
            this.crdURL = 'https://www.adviserinfo.sec.gov/Firm/' + this.firmProperties['Company CRD']
            this.finraURL = 'https://brokercheck.finra.org/firm/summary/' + this.firmProperties['Company CRD']
          }

          if(this.firmProperties['Investment Advisor Firm'] === 'on' && this.firmProperties['Brokerage Firm'] === 'on') {
            this.registrationType = 'Hybrid'
          } else if(this.firmProperties['Investment Advisor Firm'] === 'on') {
            this.registrationType = 'Registered Investment Advisor'
          } else if(this.firmProperties['Brokerage Firm'] === 'on') {
            this.registrationType = 'Brokerage Firm'
          } else {
            this.registrationType = 'Unknown'
          }

          if(this.firmProperties.address_work) {
            this.firmAddress = JSON.parse(this.firmProperties.address_work)
            this.addressDisplay = this.firmAddress.city + ', ' + this.firmAddress.state
            this.addressURL = `https://www.google.com/maps/place/${this.firmAddress.address}+${this.firmAddress.city}+${this.firmAddress.state}+${this.firmAddress.zip}`
            this.addressURL = this.addressURL.replace(' ','+')
          } else {
            this.addressDisplay = null;
            this.addressURL = null;
          }

        } else {
          this.firmProperties = [];
          this.inCRM = false;
          if (this.firm) {
            this.searchURL = 'https://athenainvest.agilecrm.com/#companies/search/' + this.firm.FirmName;
          }          
          this.companyURL = null;
          this.crdURL = null;
        }
      };
    });
  }

  arrayToObject(arr: any[], keyField: string) {
    arr.reduce((obj, item) => {
      obj[item.name] = item
      return obj
    }, {})
  }

  ngOnInit() {

    // Get FirmID from URL
    this.id = this.route.snapshot.params['id'];
    this.firm = this.assetFirm.filter((e) => e.FirmID === this.id)[0];
    this.activity = this.activityFirm.filter((e) => e.FirmID === this.id);

    if(this.firm) {
      this.firmAdvisors = this.clientService.getSummaryAssets(this.firm.Records, 'AdvisorID', ['AdvisorName','AthenaRepName'],true)
    };    

    // console.log(this.firmAdvisors);

    this.agileService.getFirmProfile(this.id);

    // All Sales and Redemptions
    this.srActivity = this.activity.filter((e) => {
      return (e.TransactionType == 'Sale' || e.TransactionType == 'Redemption')
    });

    this.srTotalActivities = this.srActivity.length;
    this.srTotalValue = this.srActivity.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Sales
    this.srSales = this.activity.filter((e) => {
      return (e.TransactionType == 'Sale')
    });

    this.srTotalSales = this.srSales.length;
    this.srTotalSalesValue = this.srSales.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Redemptions
    this.srRedemptions = this.activity.filter((e) => {
      return (e.TransactionType == 'Redemption')
    });

    this.srTotalRedemptions = this.srRedemptions.length;
    this.srTotalRedemptionsValue = this.srRedemptions.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // All Proposals
    this.propActivity = this.activity.filter((e) => {
      return (e.TransactionType == 'Proposal' || e.TransactionType == 'Proposed Redemption')
    });

    this.propTotalActivities = this.propActivity.length;
    this.propTotalValue = this.propActivity.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Proposed Sales
    this.propSales = this.activity.filter((e) => {
      return (e.TransactionType == 'Proposal')
    });

    this.propTotalSales = this.propSales.length;
    this.propTotalSalesValue = this.propSales.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);

    // Proposed Redemptions
    this.propRedemptions = this.activity.filter((e) => {
      return (e.TransactionType == 'Proposed Redemption')
    });

    this.propTotalRedemptions = this.propRedemptions.length;
    this.propTotalRedemptionsValue = this.propRedemptions.reduce((prev, curr) => {
      return prev + curr.ActivityValue
    }, 0);
    
  }

  changeTab(tabIndex: number) {
    this.selectedIndex = tabIndex;
  }

}