import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { AssetDetail, AssetAdvisor, AssetFirm, AssetPlatform, AssetAthenaRep, AssetPortfolio } from '../../models/asset-detail';

@Component({
  selector: 'app-aum',
  templateUrl: './aum.component.html',
  styleUrls: ['./aum.component.css']
})
export class AumComponent implements OnInit {

  dataIsLoaded: boolean;
  assetDate: string;

  assetDetail: AssetDetail[];
  assetAdvisor: AssetAdvisor[];
  assetFirm: AssetFirm[];
  assetPlatform: AssetPlatform[];
  assetPortfolio: AssetPortfolio[];
  assetAthenaRep: AssetAthenaRep[];

  totalMarketValue: number;
  totalAccounts: number;
  totalAnnualRevenue: number;

  advisorCount: number;
  firmCount: number;
  platformCount: number;

  advisorColumns = ['Rank','AdvisorName','AdvisorStars','FirmName','Accounts','MarketValue','AnnualRevenue','AthenaRep'];
  firmColumns = ['Rank','FirmName','FirmStars','PlatformName','Accounts','MarketValue','AnnualRevenue'];
  platformColumns = ['Rank','PlatformName','LineOfBusiness','Accounts','MarketValue','AnnualRevenue'];
  portfolioColumns = ['Rank','TargetPortfolioName','Accounts','MarketValue','AnnualRevenue'];
  athenaRepColumns = ['Rank','AthenaRepName','Accounts','MarketValue','AnnualRevenue'];

  constructor(private clientService: ClientService) {
    
  }

  ngOnInit() {    

    this.clientService.dataIsLoaded.subscribe((data: boolean) => {
      this.dataIsLoaded = data;
    });

    this.clientService.dataAssetDetail.subscribe((data: AssetDetail[]) => {
      this.assetDetail = data;
    });

    this.clientService.dataAssetAdvisor.subscribe((data: AssetAdvisor[]) => {
      this.assetAdvisor = data;
    });

    this.clientService.dataAssetFirm.subscribe((data: AssetFirm[]) => {
      this.assetFirm = data;
    });

    this.clientService.dataAssetPortfolio.subscribe((data: AssetPortfolio[]) => {
      this.assetPortfolio = data;
    });

    this.clientService.dataAssetAthenaRep.subscribe((data: AssetAthenaRep[]) => {
      this.assetAthenaRep = data;
    });

    this.clientService.dataAssetPlatform.subscribe((data: AssetPlatform[]) => {
      this.assetPlatform = data;
      if(data) {
        this.calcTotals(data)
      };      
    });

    this.clientService.assetDate.subscribe((data: string) => {
      this.assetDate = data;
    });

    // console.log(this.dataIsLoaded);
    // if (!this.dataIsLoaded) {
    //   this.clientService.getAssets();
    // };
    // this.clientService.getAssets();    
  }

  calcTotals(data: any[]) {

    this.totalAccounts = data.reduce((prev, curr) => {
      return prev + curr.Accounts
    },0);

    this.totalMarketValue = data.reduce((prev, curr) => {
      return prev + curr.MarketValue
    },0);

    this.totalAnnualRevenue = data.reduce((prev, curr) => {
      return prev + curr.AnnualRevenue
    },0);

  }

  onAdvisorClick(row: any): void {
    console.log(row);
  }

  onDownloadClick() {
    this.clientService.downloadAssets()    
  }

}
