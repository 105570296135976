import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { AumComponent } from './components/aum/aum.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { AdvisorsComponent } from './components/advisors/advisors.component';
import { AdvisorDetailsComponent } from './components/advisor-details/advisor-details.component';
import { FirmsComponent } from './components/firms/firms.component';
import { FirmDetailsComponent } from './components/firm-details/firm-details.component';
import { SettingsComponent } from './components/settings/settings.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [  
  {path: 'login', component: LoginComponent},
  {path: '', component: LoginComponent},
  {path: 'aum', component: AumComponent, canActivate:[AuthGuard]},
  {path: 'activities', component: ActivitiesComponent, canActivate:[AuthGuard]},
  {path: 'advisors', component: AdvisorsComponent, canActivate:[AuthGuard]},
  {path: 'advisors/:id', component: AdvisorDetailsComponent, canActivate:[AuthGuard]},
  {path: 'firms', component: FirmsComponent, canActivate:[AuthGuard]},
  {path: 'firms/:id', component: FirmDetailsComponent, canActivate:[AuthGuard]},
  {path: 'settings', component: SettingsComponent, canActivate:[AuthGuard]},
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [AuthGuard]
})
export class AppRoutingModule { }