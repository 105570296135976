import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.css']
})
export class UserIconComponent implements OnInit {

  @Input('athenaRepName') private athenaRepName: string;

  userIconClass: string = '';
  userIconTransform: string = '';
  users: any[];

  constructor() { }

  ngOnInit() {

    this.userIconTransform = 'rotate--90'

    this.users =
      [
        {
          AthenaRepName: 'David Stock',
          UserIconClass: 'fas fa-lg fa-fighter-jet ath-blue',
        },
        {
          AthenaRepName: 'Lambert Bunker',
          UserIconClass: 'fas fa-lg fa-tape ath-red',
        },
        {
          AthenaRepName: 'Steve Bogosian',
          UserIconClass: 'fas fa-lg fa-hockey-puck ath-black',
        },
        {
          AthenaRepName: 'Tom Howard',
          UserIconClass: 'fas fa-lg fa-graduation-cap ath-purple',
        },
        {
          AthenaRepName: 'Andrew Howard',
          UserIconClass: 'fas fa-lg fa-glass-martini ath-green',
        },
      ];

    this.users.forEach((i) => {
      if (i.AthenaRepName == this.athenaRepName) {
        this.userIconClass = i.UserIconClass;
      }
    });

  }

}
