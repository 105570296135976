import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule, MatTabsModule, MatButtonModule, MatIconModule, MatSnackBarModule, matSnackBarAnimations } from '@angular/material';

import { AppRoutingModule } from './app-routing.module'
import { FlashMessagesModule } from 'angular2-flash-messages';

// Application Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SettingsComponent } from './components/settings/settings.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { AumComponent } from './components/aum/aum.component';
import { AdvisorsComponent } from './components/advisors/advisors.component';
import { AdvisorDetailsComponent } from './components/advisor-details/advisor-details.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';

// Application Services
import { AuthService } from './services/auth.service';
import { ClientService } from './services/client.service';
import { AgileService } from './services/agile.service';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { FirmsComponent } from './components/firms/firms.component';
import { FirmDetailsComponent } from './components/firm-details/firm-details.component';
import { FilterDataComponent } from './components/filter-data/filter-data.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SettingsComponent,
    NotFoundComponent,
    LoginComponent,
    ActivitiesComponent,
    AumComponent,
    AdvisorsComponent,
    AdvisorDetailsComponent,
    StarRatingComponent,
    UserIconComponent,
    FirmsComponent,
    FirmDetailsComponent,
    FilterDataComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    AppRoutingModule,
    FlashMessagesModule.forRoot(),
  ],
  providers: [
    AuthService,
    ClientService,
    AgileService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
