import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  errorMessage: string;

  firstName: string;
  lastName: string;
  newPassword: string;

  forceChange: boolean;
  loggedIn: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private flashMessage: FlashMessagesService
  ) { }

  ngOnInit() {
    this.authService.authForceChange.subscribe(
      (forceChange: boolean) => this.forceChange = forceChange
    )
  }

  onLoginSubmit() {
    const username = this.email;
    const password = this.password;
    if(username === '') {
      this.flashMessage.show('Type an email, dummy.', { cssClass: 'alert-warning', timeout: 4000});
      return;
    }
    if(password === '') {
      this.flashMessage.show('Need a password, dum dum.', { cssClass: 'alert-warning', timeout: 4000});
      return;
    }
    this.authService.login(username, password, (err, res) => {
      if(err) {
        console.log(err);
      } else {
        console.log(res);
      }
    }, this.firstName, this.lastName, this.newPassword);
    // this.flashMessage.show('You are now logged in', { cssClass: 'alert-success', timeout: 4000});
    // this.router.navigate(['/aum']);
  }
}
