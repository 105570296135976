import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';

import { Angular5Csv } from 'angular5-csv/Angular5-csv';

import { AssetDetail, AssetAdvisor, Advisor, Firm, AssetFirm, AssetPlatform, AssetPortfolio, AssetAthenaRep } from '../models/asset-detail';
import { ActivityDetail, ActivitySummary } from '../models/activity-detail';

import { AuthService } from '../services/auth.service';

@Injectable()
export class ClientService {
  dataEdited = new BehaviorSubject<boolean>(false);
  dataIsLoading = new BehaviorSubject<boolean>(false);
  dataIsLoaded = new BehaviorSubject<boolean>(false);

  dataAssetDetail = new BehaviorSubject<AssetDetail[]>(null);

  dataAssetAdvisor = new BehaviorSubject<AssetAdvisor[]>(null);
  dataAssetAdvisorCard = new BehaviorSubject<Advisor[]>(null);

  dataAssetFirm = new BehaviorSubject<AssetFirm[]>(null);
  dataAssetPlatform = new BehaviorSubject<AssetPlatform[]>(null);
  dataAssetPortfolio = new BehaviorSubject<AssetPortfolio[]>(null);
  dataAssetAthenaRep = new BehaviorSubject<AssetAthenaRep[]>(null);  
  dataLoadFailed = new Subject<boolean>();

  assetDate = new BehaviorSubject<string>(null);

  initAssetDetail = new BehaviorSubject<AssetDetail[]>(null);
  filteredAssetData: AssetDetail[];
  filteredActivityData: ActivityDetail[];
  filteredAdvisorData: Advisor[];

  initAdvisors = new BehaviorSubject<Advisor[]>(null);
  dataAdvisors = new BehaviorSubject<Advisor[]>(null);
  advisorCount = new BehaviorSubject<number>(null);

  firmCount = new BehaviorSubject<number>(null);

  dataActivityDetail = new BehaviorSubject<ActivityDetail[]>(null);
  initActivityDetail = new BehaviorSubject<ActivityDetail[]>(null);

  dataActivityProduction = new BehaviorSubject<ActivitySummary[]>(null);
  dataActivityRedemption = new BehaviorSubject<ActivitySummary[]>(null);
  dataActivityProposals = new BehaviorSubject<ActivitySummary[]>(null);

  constructor(private http: Http, private authService: AuthService) {
    this.getAssets();
    this.getActivities();
  }

  getAssets() {

    this.dataLoadFailed.next(false);
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      this.http.get('https://sfo80nedie.execute-api.us-east-1.amazonaws.com/alpha/assets', {
        headers: new Headers({'Authorization': session.getIdToken().getJwtToken()})
      })
        .map(
          (response: Response) => response.json()
        )
        .subscribe(
          (data) => {
            this.initAssetDetail.next(data);
            this.dataAssetDetail.next(data);
            this.assetDate.next(data[data.length - 1].AssetDate);
            // console.log(data);
            this.createAssetArrays(data);
            this.dataIsLoaded.next(true);
            this.createAdvisorList();
          },
          (error) => {
            console.log(error);
            this.dataLoadFailed.next(true);
            this.dataAssetDetail.next(null);
          }
        );
    });

  }

  getActivities() {
    
    this.authService.getAuthenticatedUser().getSession((err, session) => {
      this.http.get('https://sfo80nedie.execute-api.us-east-1.amazonaws.com/alpha/activities', {
        headers: new Headers({'Authorization': session.getIdToken().getJwtToken()})
      })
        .map(
          (response: Response) => response.json()
        )
        .subscribe(
          (data) => {
            this.initActivityDetail.next(data);
            this.dataActivityDetail.next(data);
            // console.log(data);
            this.createActivityArrays(data);
            // this.createAssetArrays(data);
            // this.dataIsLoaded.next(true);
          },
          (error) => {
            console.log(error);
            // this.dataLoadFailed.next(true);
            // this.dataAssetDetail.next(null);
          }
        );
    });
  }

  filterData(rep: string) {

    if(rep && rep != '') {

      if(rep == 'All Athena Reps') {

        this.initAssetDetail.subscribe((data: AssetDetail[]) => {
          this.filteredAssetData = data
        });

        this.initActivityDetail.subscribe((data: ActivityDetail[]) => {
          this.filteredActivityData = data
        });

        this.initAdvisors.subscribe((data: Advisor[]) => {
          this.filteredAdvisorData = data
        });

      } else if(rep == 'No Rep Assigned') {

        this.initAssetDetail.subscribe((data: AssetDetail[]) => {
          this.filteredAssetData = data.filter((e) => e.AthenaRepName == null)
        });

        this.initActivityDetail.subscribe((data: ActivityDetail[]) => {
          this.filteredActivityData = data.filter((e) => e.AthenaRepName == null)
        });

        this.initAdvisors.subscribe((data: Advisor[]) => {
          this.filteredAdvisorData = data.filter((e) => e.AthenaRepName == null)
        });

      } else {

        this.initAssetDetail.subscribe((data: AssetDetail[]) => {
          this.filteredAssetData = data.filter((e) => e.AthenaRepName == rep)
        });

        this.initActivityDetail.subscribe((data: ActivityDetail[]) => {
          this.filteredActivityData = data.filter((e) => e.AthenaRepName == rep)
        });

        this.initAdvisors.subscribe((data: Advisor[]) => {
          this.filteredAdvisorData = data.filter((e) => e.AthenaRepName == rep)
        });

      }
      
      this.dataAssetDetail.next(this.filteredAssetData);
      this.createAssetArrays(this.filteredAssetData);
      this.dataActivityDetail.next(this.filteredActivityData);
      this.dataAdvisors.next(this.filteredAdvisorData);
      this.advisorCount.next(this.filteredAdvisorData.length);
    }

  }

  createAdvisorList() {
    this.dataAssetAdvisorCard.subscribe((assetData: Advisor[]) => {
      if (assetData) {
        this.dataActivityDetail.subscribe((activityData: ActivityDetail[]) => {
          if (activityData) {
            activityData.forEach((e) => {
              if (e.AdvisorID == null) {
                let found = assetData.some((f) => {
                  return f.AdvisorName === e.AdvisorName && f.AdvisorID == null
                })
                if (!found) {
                  assetData.push({
                    AdvisorID: e.AdvisorID,
                    AdvisorName: e.AdvisorName,
                    AdvisorStars: 0,
                    FirmID: e.FirmID,
                    FirmName: e.FirmName,
                    PlatformName: e.PlatformName,
                    AthenaRepName: e.AthenaRepName,
                    MarketValue: 0,
                    Accounts: 0,
                    AnnualRevenue: 0,
                    Rank: 0,
                    Status: 'No ID'
                  })
                }
              } else {
                let found = assetData.some((f) => {
                  return f.AdvisorID === e.AdvisorID
                })
                if (!found) {
                  assetData.push({
                    AdvisorID: e.AdvisorID,
                    AdvisorName: e.AdvisorName,
                    AdvisorStars: 0,
                    FirmID: e.FirmID,
                    FirmName: e.FirmName,
                    PlatformName: e.PlatformName,
                    AthenaRepName: e.AthenaRepName,
                    MarketValue: 0,
                    Accounts: 0,
                    AnnualRevenue: 0,
                    Rank: 0,
                    Status: (!e.AthenaRepName) ? 'Unassigned' : null
                  })
                }
              }
            })
          }
        })

        this.initAdvisors.next(assetData)        
        this.dataAdvisors.next(assetData)
        this.advisorCount.next(assetData.length)
        // console.log(assetData)

      }
    })
  }

  // Construct the various views in the asset area
  createAssetArrays(data) {

    // Get summary data grouped by advisor
    this.dataAssetAdvisor.next(this.getSummaryAssets(data, 'AdvisorID', ['AdvisorName','AdvisorStars','FirmID','FirmName','AthenaRepName']));
    this.dataAssetAdvisorCard.next(this.getSummaryAssets(data, 'AdvisorID', ['AdvisorName','AdvisorStars','FirmID','FirmName','PlatformName','AthenaRepName'],true)); 

    // Get summary data grouped by firm
    this.dataAssetFirm.next(this.getSummaryAssets(data, 'FirmID', ['FirmName','PlatformName','FirmStars']));

    // Get summary data grouped by platform
    this.dataAssetPlatform.next(this.getSummaryAssets(data, 'PlatformName', ['LineOfBusiness']));

    // Get summary data grouped by target portfolio
    this.dataAssetPortfolio.next(this.getSummaryAssets(data, 'TargetPortfolioName', ['AthenaRepID']));

    // Get summary data grouped by athena rep
    this.dataAssetAthenaRep.next(this.getSummaryAssets(data, 'AthenaRepName', ['AthenaRepID']));
  }

  getSummaryAssets(data: any[], key: string, attrList: string[], dropDetail?: boolean): any[] {

    // Push key and grouped data elements into final array
    const final = [];
    this.getUniqueSet(data, key).map(elem => {
      final.push({[key]: elem, Records: data.filter(e => elem === e[key])})
    });

    // Get additional attributes and subtotals
    final.map((elem, idx, arr) => {

      // Add defined attributes
      attrList.forEach((attrName) => {
        elem[attrName] = arr[idx].Records[0][attrName];
      });

      // Add total accounts and market values for the defined groups
      const recs = arr[idx].Records;
      elem.MarketValue = recs.reduce((prev, curr) => {
        return prev + curr.MarketValue;
      }, 0 );
      elem.Accounts = recs.reduce((prev, curr) => {
        return prev + curr.Accounts;
      }, 0 );
      elem.AnnualRevenue = recs.reduce((prev, curr) => {
        return prev + curr.AnnualRevenue;
      }, 0 );
    });

    // Optionally destroy the temporary groups
    if(dropDetail) {
      final.forEach((i) => delete i.Records);
    }

    // Sort from highest annual revenue to lowest
    final.sort((a, b) => {
      return b.AnnualRevenue - a.AnnualRevenue;
    });

    // Add rank field
    final.map((elem, idx, arr) => {
      elem.Rank = idx + 1
    });

    if(key === 'FirmID') {
      this.firmCount.next(final.length);
    }

    // Return final data
    return final;

  }

  getUniqueSet(data: any[], elementName: string): any[] {
    return data
      .map((elem) => elem[elementName])
      .filter((e, i, a) => a.indexOf(e) === i);
  }

  createActivityArrays(data) {

    // Get summary data grouped by advisor
    this.dataActivityProduction.next(this.getSummaryActivities(data, 'AdvisorID', 'Sale', ['AdvisorName','FirmID','FirmName','AthenaRepName']));

    this.dataActivityRedemption.next(this.getSummaryActivities(data, 'AdvisorID', 'Redemption', ['AdvisorName','FirmID','FirmName','AthenaRepName']));

    this.dataActivityProposals.next(this.getSummaryActivities(data, 'AdvisorID', 'Proposal', ['AdvisorName','FirmID','FirmName','AthenaRepName']));

  }

  getSummaryActivities(data: any[], key: string, activityFilter: string, attrList: string[]): any[] {

    // Push key and grouped data elements into final array
    const final = [];

    // Filter for Activity Type
    const atfData = data.filter((e) => {
      return e.TransactionType.includes(activityFilter)
    });

    this.getUniqueSet(atfData, key).map(elem => {
      final.push({[key]: elem, TransactionType: activityFilter, Records: atfData.filter(e => elem === e[key])})
    });

    // Get additional attributes and subtotals
    final.map((elem, idx, arr) => {

      // Add defined attributes
      attrList.forEach((attrName) => {
        elem[attrName] = arr[idx].Records[0][attrName];
      });

      // Add total accounts and market values for the defined groups
      const recs = arr[idx].Records;
      
      elem.ActivityValue = recs.reduce((prev, curr) => {
        return prev + curr.ActivityValue;
      }, 0 );
      elem.Activities = recs.length;
    });

    // Destroy the temporary groups
    final.forEach((i) => delete i.Records);

    // Variable sort
    switch(activityFilter) {
      case 'Sale': {
        final.sort((a, b) => {
          return b.ActivityValue - a.ActivityValue;
        });
        break;
      }
      case 'Redemption': {
        final.sort((a, b) => {
          return b.Activities - a.Activities || a.ActivityValue - b.ActivityValue;
        });
        break;
      }
      case 'Proposal': {
        final.sort((a, b) => {
          return b.ActivityValue - a.ActivityValue;
        });
        break;
      }
    };    

    // Add rank field
    final.map((elem, idx, arr) => {
      elem.Rank = idx + 1
    });

    // Return final data
    // console.log(final);
    return final;

  }

  getCurrentAdvisor(id: string): AssetAdvisor[] {
    let assetAdvisor: AssetAdvisor[];
    this.dataAssetAdvisor.subscribe((data: AssetAdvisor[]) => {
      assetAdvisor = data;
    });
    return assetAdvisor.filter((e) => e.AdvisorID === id);
  }

  getCurrentActivity(id: string): ActivityDetail[] {
    let activity: ActivityDetail[];
    this.dataActivityDetail.subscribe((data: ActivityDetail[]) => {
      activity = data;
    });
    return activity.filter((e) => e.AdvisorID === id);
  }

  downloadAssets() {
    let assetDetail: AssetDetail[];
    this.dataAssetDetail.subscribe((data: AssetDetail[]) => {
      assetDetail = data;
    });

    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        'AssetDate',
        'LobCode',
        'LineOfBusiness',
        'PlatformName',
        'AthenaRepId',
        'AthenaRepName',
        'EnterpriseId',
        'EnterpriseName',
        'FirmId',
        'FirmName',
        'FirmSegment',
        'FirmStars',
        'AdvisorId',
        'AdvisorName',
        'AdvisorStars',
        'TargetPortfolioName',
        'Accounts',
        'MarketValue',
        'ManagementFee',
        'AnnualRevenue',
        'Activities',
        'NetActivityValue'
      ]
    };
    
    new Angular5Csv(assetDetail, 'Assets', options);
  }

  downloadActivities() {
    let activityDetail: ActivityDetail[];
    this.dataActivityDetail.subscribe((data: ActivityDetail[]) => {
      activityDetail = data;
    });

    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true, 
      showTitle: false,
      useBom: true,
      noDownload: false,
      headers: [
        'ActivityDate',
        'PlatformName',
        'AthenaRepId',
        'AthenaRepName',
        'EnterpriseId',
        'EnterpriseName',
        'FirmId',
        'FirmName',
        'AdvisorId',
        'AdvisorName',
        'TransactionType',
        'ActivityId',
        'TargetPortfolioName',
        'ActivityValue',
        'ActivityStatus'
      ]
    };
    
    new Angular5Csv(activityDetail, 'Activities', options);
  }

}
