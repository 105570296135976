import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { AssetFirm } from '../../models/asset-detail';

@Component({
  selector: 'app-firms',
  templateUrl: './firms.component.html',
  styleUrls: ['./firms.component.css']
})
export class FirmsComponent implements OnInit {

  dataFirms: AssetFirm[];
  firmCount: number;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.clientService.dataAssetFirm.subscribe((data: AssetFirm[]) => {
      this.dataFirms = data;
    });

    this.clientService.firmCount.subscribe((data: number) => {
      this.firmCount = data;
    });

  }

}
