import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isAuthenticated: boolean;
  loggedInUser: string;
  showRegister: boolean;

  constructor(
    private authService: AuthService,
    private flashMessage: FlashMessagesService,
  ) { }

  ngOnInit() {   
    this.authService.authIsLoggedIn.subscribe(
      (isAuthenticated: boolean) => this.isAuthenticated = isAuthenticated
    );    
  }

  onLogoutClick() {
    this.authService.logout();
    this.flashMessage.show('You are now logged out', { cssClass: 'alert-success', timeout: 4000 });
  }

}
